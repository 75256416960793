import React from 'react';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { I18nextProvider } from 'react-i18next';
import UserRoutes from './components/Routes/UserRoutes.jsx';
import i18n from './components/helpers/i18next';
import Header from 'components/Header';
import Footer from 'components/Footer';
import ModalWindow from 'components/Shared/ModalWindow/index.js';
import ScrollToTopOnRouteChange from './components/helpers/ScrollToTopOnRouteChange';
import ScrollToTopButton from './components/Shared/ScrollToTopButton';
import AdvertisingWindow from 'components/Shared/AdvertisingWindow';
import { getModalWindowStatus } from './redux/technical/technical-selectors.js';
import SchemaOrg from 'components/helpers/SchemaOrg';

function App() {
  const isShowModal = useSelector(getModalWindowStatus);

  const location = useLocation();
  useEffect(() => {
    const pathname = location.pathname;
    const language = pathname.substring(1, 3);
    if (language === 'en' || language === 'es' || language === 'ru') {
      i18n.changeLanguage(language);
    }
  }, [location]);

  const googleAnalyticsScript = `
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-SHGC7JX2QE');
  `;

  return (
    <I18nextProvider i18n={i18n}>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-SHGC7JX2QE"
        />
        <script>{googleAnalyticsScript}</script>
      </Helmet>
      <SchemaOrg />
      <div
        style={{
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Header />
        <main>
          <ScrollToTopOnRouteChange />
          <ScrollToTopButton />
          <AdvertisingWindow />
          <UserRoutes />
        </main>
        <Footer />
        {isShowModal && <ModalWindow />}
      </div>
    </I18nextProvider>
  );
}

export default App;
