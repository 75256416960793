import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { SiGooglemaps } from 'react-icons/si';
import { MdLocalPhone, MdOutlineEmail } from 'react-icons/md';
import { BsWhatsapp } from 'react-icons/bs';

import s from './Footer.module.scss';

const Footer = () => {
  const { t } = useTranslation();
  const isDesctop = useMediaQuery({ minWidth: 1280 });
  const [basePath, setBasePath] = useState(`/${i18n.language}`);

  useEffect(() => {
    const handleLanguageChange = () => {
      setBasePath(`/${i18n.language}`);
    };
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [basePath]);

  return (
    <footer className={s.footer}>
      <div className="container">
        <div className={s.footer__content}>
          <div className={s.footer__partLeft}>
            <Helmet>
              <title>Footer - Contact Information</title>
              <meta
                name="description"
                content="Find information about our working hours and clinic address."
              />
              <meta
                name="description"
                lang="es"
                content="Encuentra información sobre nuestros horarios de trabajo y la dirección de la clínica."
              />
            </Helmet>
            <span className={s.footer__partTitle}>Art Studio Dental</span>
            <Link className={s.footer__link} to={`${basePath}/contacts`}>
              <div className={s.footer__iconContainer}>
                <SiGooglemaps color="white" size={isDesctop ? 17 : 15} />
              </div>
              <div className={s.footer__text}>
                <address>
                  Calle Bruselas, 10, isla IV, 43-C, Playa D'Aro, Girona, 17250,
                  Spain
                </address>
              </div>
            </Link>
            <a className={s.footer__link} href="tel:+34614258852">
              <div className={s.footer__iconContainer}>
                <MdLocalPhone color="white" size={isDesctop ? 17 : 15} />
              </div>
              <span className={s.footer__text}>+34 614 258 852</span>
            </a>
            <a
              className={s.footer__link}
              href="https://wa.me/+34614258852"
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className={s.footer__iconContainer}>
                <BsWhatsapp color="white" size={isDesctop ? 17 : 15} />
              </div>
              <span className={s.footer__text}>WhatsApp</span>
            </a>
            <a
              className={s.footer__link}
              href="mailto:artstudiodental1@gmail.com"
              target="_blank"
              rel="noreferrer noopener"
            >
              <div className={s.footer__iconContainer}>
                <MdOutlineEmail color="white" size={isDesctop ? 19 : 17} />
              </div>
              <span className={s.footer__text}>
                Email: artstudiodental1@gmail.com
              </span>
            </a>
            <div className={s.footer__partS}>
              <span className={s.footer__partTitle}>{t('Schedule')}</span>
              <span className={s.footer__textP}>
                {t('Mon-Sun: 9:00 — 21:00')}
              </span>
            </div>
          </div>
          <div className={s.footer__partRight}>
            <Helmet>
              <title>Footer - Services</title>
              <meta
                name="description"
                content="Learn about the services offered at our clinic: Dental treatment, Hygiene and whitening, Prosthetics, Implantology, Orthodontics, CT scan."
              />
              <meta
                name="description"
                lang="es"
                content="Aprenda sobre los servicios que ofrece la clínica: Tratamiento dental, Higiene y blanqueamiento, Prótesis, Implantología, Ortodoncia, TAC."
              />
            </Helmet>
            <span className={s.footer__partTitle}>{t('Services')}</span>
            <Link
              className={s.footer__link}
              to={`${basePath}/services#dental-treatment`}
            >
              <span className={s.footer__textS}>{t('Dental treatment')}</span>
            </Link>
            <Link
              className={s.footer__link}
              to={`${basePath}/services#hygiene`}
            >
              <span className={s.footer__textS}>
                {t('Hygiene and whitening')}
              </span>
            </Link>
            <Link
              className={s.footer__link}
              to={`${basePath}/services#prosthetics`}
            >
              <span className={s.footer__textS}>{t('Prosthetics')}</span>
            </Link>
            <Link
              className={s.footer__link}
              to={`${basePath}/services#implantology`}
            >
              <span className={s.footer__textS}>{t('Implantology')}</span>
            </Link>
            <Link
              className={s.footer__link}
              to={`${basePath}/services#orthodontics`}
            >
              <span className={s.footer__textS}>{t('Orthodontics')}</span>
            </Link>
            <Link
              className={s.footer__link}
              to={`${basePath}/services#ct-scan`}
            >
              <span className={s.footer__textS}>{t('CT scan')}</span>
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
