import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { setModalWindowStatus } from '../../../redux/technical/technical-slice';
import EclinicCitasWidget from '../EclinicCitasWidget/EclinicCitasWidget';
import { TfiClose } from 'react-icons/tfi';
import s from './ModalWindow.module.scss';
import './ModalWindow.css';

const ModalWindow = () => {
  const modalRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    const handleOutsideClick = e => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        dispatch(setModalWindowStatus(false));
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [dispatch]);

  const closeModal = () => {
    dispatch(setModalWindowStatus(false));
  };

  return (
    <div className={s.window} ref={modalRef}>
      <div className={s.window__border}>
        <button className={s.window__closeButton} onClick={closeModal}>
          <TfiClose color="#017683" size={15} />
        </button>
        <div className={s.window__widget}>
          <EclinicCitasWidget />
        </div>
      </div>
    </div>
  );
};

export default ModalWindow;
