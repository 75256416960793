import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modalWindowStatus: false,
  error: '',
  message: '',
};

const technical = createSlice({
  name: 'technical',
  initialState,
  reducers: {
    setModalWindowStatus: (store, action) => {
      store.modalWindowStatus = action.payload;
    },
    clearTechnicalError: store => {
      store.error = '';
    },
    clearTechnicalMessage: store => {
      store.message = '';
    },
  },
  extraReducers: builder => {},
});

export default technical.reducer;
export const {
  setModalWindowStatus,
  clearTechnicalError,
  clearTechnicalMessage,
} = technical.actions;
