import React from 'react';
import s from './LanguageSelect.module.scss';

const LanguageSelect = ({ changeLanguage, currentLanguage }) => {
  const handleSelectChange = event => {
    changeLanguage(event.target.value);
  };

  return (
    <label className={s.label}>
      Виберіть мову:
      <select
        className={s.select}
        value={currentLanguage ? currentLanguage : 'en'}
        onChange={handleSelectChange}
      >
        <option className={s.option} value="en">
          EN
        </option>
        <option className={s.option} value="es">
          ES
        </option>
        <option className={s.option} value="ru">
          RU
        </option>
      </select>
    </label>
  );
};

export default LanguageSelect;
